<template>
  <section>
    <Toast />
    <div class="w-full">
      <div class="w-full flex justify-between gap-2">
        <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filtros.U_ACS_NmrIdentf.value" @input="listarPacientes()" placeholder="Buscar documento" />
        </span>
        <div class="flex gap-2">
          <div v-if="contratosStore._pacientes_ingresados">
            <div class="bg-blue-300 flex justify-between rounded-md p-2 text-blue-600 font-bold text-sm border border-r-2">
              <div class="flex gap-2">
                <p>{{ documento ? documento.name : 'pacientes.xlsx' }}</p>
              </div>
              <button @click="eliminarArchivo">
                <i class="pi pi-times text-sm"></i>
              </button>
            </div>
          </div>
          <FileUpload
            v-else
            mode="basic"
            name="demo[]"
            url="./upload.php"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            class="bg-white text-blue-600"
            chooseLabel="Actualizar pacientes masivo"
            @change="subirPaciente()"
            ref="file"
          ></FileUpload>
          <Button @click="contratosStore.descargarPlantillaMasivoPacientes()" v-if="!contratosStore._pacientes_ingresados" label="Descargar plantilla" icon="pi pi-download" severity="secondary" />
          <Button label="Guardar actualización pacientes masivo" v-if="contratosStore._pacientes_ingresados" icon="pi pi-save" @click="actualizarPacientesMasivo" severity="success" />
          <Button label="Agregar paciente" icon="pi pi-plus" v-else @click="displayModalCrearPaciente = true" severity="success" />
        </div>
      </div>
      <div class="w-full mt-4">
        <DataTable showGridlines :value="contratosStore._paginado_pacientes.rows" class="text-sm p-datatable-sm" tableStyle="min-width: 50rem">
          <Column field="U_ACS_NmrIdentf" header="Documento">
            <template #body="slotprops">
              {{ slotprops.data.Paciente.U_ACS_TpoIdentf + ' - ' + slotprops.data.Paciente.U_ACS_NmrIdentf }}
            </template>
          </Column>
          <Column field="Paciente.U_ACS_NmbPct" header="Nombre"></Column>
          <Column field="Paciente.U_ACS_Regimen" header="Regimen"></Column>
          <Column field="Paciente.U_ACS_Edad" header="Edad"></Column>
          <Column field="Paciente.U_ACS_Ciudad" header="Zonificacion">
            <template #body="slotprops">
              {{ slotprops.data?.Paciente.U_ACS_Ciudad + ', ' + slotprops.data?.Paciente.U_ACS_Dpto }}
            </template>
          </Column>
          <Column field="FecInicio" header="Fecha inicio">
            <template #body="slotprops">
              {{ $h.formatDate(slotprops.data.FecInicio) }}
            </template>
          </Column>
          <Column field="FecFin" header="Fecha fin">
            <template #body="slotprops">
              {{ $h.formatDate(slotprops.data.FecFin) }}
            </template>
          </Column>
        </DataTable>
        <Paginator :rows="10" @page="onPage($event)" :totalRecords="contratosStore._paginado_pacientes.count" :rowsPerPageOptions="[10, 20, 30, 100, 500]"></Paginator>
      </div>
    </div>
    <Dialog modal v-model:visible="displayModalCrearPaciente" header="Crear paciente" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="w-full">
        <div>
          <label for="" class="italic text-xs text-gray-600">Identificación</label>
          <div class="flex gap-2">
            <Dropdown :class="creando && !contratosStore._paciente_ingresar.TipoIdent ? 'border-red-600' : ''" v-model="contratosStore.paciente_ingresar.TipoIdent" optionLabel="U_ACS_TpoIdentf" optionValue="U_ACS_TpoIdentf" :options="contratosStore._tipos_identificacion" placeholder="" class="w-min" />
            <InputText :class="creando && !contratosStore._paciente_ingresar.NoIdent ? 'p-invalid' : ''" type="text" class="w-full" v-model="contratosStore.paciente_ingresar.NoIdent" />
          </div>
          <span v-if="creando && (!contratosStore._paciente_ingresar.TipoIdent || !contratosStore._paciente_ingresar.NoIdent)" class="text-red-600 text-xs italic">Este campo es requerido</span>
        </div>
        <div class="flex gap-2 w-full">
          <div class="w-full">
            <label for="" class="italic text-xs text-gray-600">Fecha inicio</label>
            <input :class="creando && !contratosStore._paciente_ingresar.FechaInicio ? 'border-red-600' : ''" :max="contratosStore._paciente_ingresar.FechaFin" class="w-full border p-2 rounded-md" type="date" v-model="contratosStore.paciente_ingresar.FechaInicio">
            <span v-if="creando && (!contratosStore._paciente_ingresar.FechaInicio)" class="text-red-600 text-xs italic">Este campo es requerido</span>
          </div>
          <div class="w-full">
            <label for="" class="italic text-xs text-gray-600">Fecha fin</label>
            <input :class="creando && !contratosStore._paciente_ingresar.FechaFin ? 'border-red-600' : ''" :min="contratosStore._paciente_ingresar.FechaInicio" class="w-full border p-2 rounded-md" type="date" v-model="contratosStore.paciente_ingresar.FechaFin">
            <span v-if="creando && (!contratosStore._paciente_ingresar.FechaFin)" class="text-red-600 text-xs italic">Este campo es requerido</span>
          </div>
        </div>
        <div class="w-full flex justify-end gap-2 mt-2">
          <Button label="Cancelar" @click="cerrarModal" severity="secondary" icon="pi pi-times" />
          <Button label="Guardar" @click="guardarPaciente" icon="pi pi-save" />
        </div>
      </div>
    </Dialog>
  </section>
</template>
<script>
  import { useContratosStore } from '../../../stores/contratos.store'
  import { useRoute } from 'vue-router'
  import { useToast } from 'primevue/usetoast'
  import { ref, computed, onMounted } from 'vue'
  import { FilterMatchMode } from 'primevue/api'
  // import ServiceContrato from '../../../services/contrato-precio.service'
  import Swal from 'sweetalert2'
  import { helper } from '@/utils/helper'
  export default {
    name: 'crearContratoPacientes',
    setup () {
      // const _serviceContrato = new ServiceContrato()
      const contratosStore = useContratosStore()
      const route = useRoute()
      const toast = useToast()
      const limit = ref(10)
      const offset = ref(0)
      const documento = ref()
      const pacientes = ref([])
      const file = ref()
      const filtros = ref({
        U_ACS_NmrIdentf: { value: null, matchMode: FilterMatchMode.CONTAINS }
      })
      const creando = ref(false)
      const displayModalCrearPaciente = ref(false)
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value
        }
      })
      const subirPaciente = async () => {
        const archivo = file.value.files[0]
        documento.value = archivo
        if (archivo) {
          const reader = new FileReader()
          reader.onload = async (event) => {
            pacientes.value = archivo
            contratosStore.pacientes_ingresados = await helper.base64String(archivo)
            // const validaPac = await _serviceContrato.validPacientes({ base64: await helper.base64String(archivo) })
            // if (validaPac.data) {
            //   Swal.fire({
            //     icon: 'warning',
            //     title: 'Validacion Pacientes',
            //     text: validaPac.data
            //   })
            // }
          }
          reader.readAsArrayBuffer(archivo)
        }
      }
      const listarPacientes = () => {
        const busqueda = {}
        for (const [key, value] of Object.entries(filtros.value)) {
          if (value.value) {
            busqueda[key] = value.value
          }
        }
        const info = {
          ...params.value,
          ...busqueda
        }
        contratosStore.paginarPacientes(route.params.id, info)
      }
      const onPage = ({ rows, first }) => {
        limit.value = rows
        offset.value = first
        listarPacientes()
      }
      const guardarPaciente = () => {
        creando.value = true
        for (const [key, value] of Object.entries(contratosStore.paciente_ingresar)) {
          if (!value) return toast.add({ severity: 'error', summary: 'Error', detail: `Debes completar todos los campos falta: ${key}`, life: 3000 })
        }
        contratosStore.crearPacientesContrato(route.params.id).then(() => {
          Swal.fire({
            title: 'Guardado!',
            text: 'Paciente creado con éxito!',
            icon: 'success'
          }).then(() => {
            cerrarModal()
            listarPacientes()
          })
        })
      }
      const cambiarStatus = (value) => {
        contratosStore.actualizarEstadoPaciente(route.params.id, value.PacienteId, { Status: value.Status }).then((data) => {
          toast.add({ severity: 'success', summary: 'Estado cambiado', detail: `Paciente ${value.Paciente.U_ACS_NmbPct} actualizado`, life: 3000 })
        })
      }
      const cerrarModal = () => {
        contratosStore.paciente_ingresar = {
          TipoIdent: '',
          NoIdent: '',
          FechaInicio: '',
          FechaFin: ''
        }
        creando.value = false
        displayModalCrearPaciente.value = false
      }
      const eliminarArchivo = () => {
        file.value = null
        documento.value = null
        contratosStore.pacientes_ingresados = ''
      }
      const actualizarPacientesMasivo = () => {
        contratosStore.crearPacientesContrato(route.params.id, { base64: contratosStore.pacientes_ingresados }).then(() => {
          Swal.fire({
            title: 'Guardado!',
            text: 'Listado de pacientes actualizado con éxito',
            icon: 'success'
          }).then(() => {
            eliminarArchivo()
            listarPacientes()
          })
        })
      }
      onMounted(() => {
        listarPacientes()
        contratosStore.listarTiposIdPaciente()
      })
      return {
        onPage,
        contratosStore,
        displayModalCrearPaciente,
        cerrarModal,
        guardarPaciente,
        creando,
        cambiarStatus,
        filtros,
        listarPacientes,
        subirPaciente,
        file,
        documento,
        eliminarArchivo,
        actualizarPacientesMasivo
      }
    }
  }
</script>
